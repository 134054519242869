<template>
    <div v-if="orderPrice.order_monthly_pay || orderPrice.order_once_pay">
        <div class="payment-info-wrapper pt-2">    
            <div class="border-bottom py-2 d-flex justify-content-between">
                Frekvencia platby <strong>{{ orderIsPartialPayment ? 'Mesačná platba' : 'Jednorázová platba' }}</strong>
            </div>
            <div v-if="orderIsPartialPayment" class="border-bottom py-2 d-flex justify-content-between">
                Počet mesiacov <strong>{{ orderPrice.order_monthly_pay.partial_payments }}</strong>
            </div>
            <div class="py-2 d-flex justify-content-between">
                {{ orderIsPartialPayment ? 'Mesačná platba' : 'Výška platby' }} <strong>{{ orderIsPartialPayment ? orderPrice.order_monthly_pay.price_monthly : orderPrice.order_once_pay.price }}€</strong>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
  
export default {
    computed: {
        ...mapState('order', {
            orderPrice: 'orderPrice',
            orderIsPartialPayment: 'isPartialPayment'
        }),
    },
}
</script>

<style lang="sass" scoped>
.payment-info-wrapper
    background: #fff
    font-size: 14px
    font-weight: 400

</style>
